import $ from 'jquery';
import { Modal } from 'bootstrap';

export const NewsletterConsent = () => {
    const newsletterConsentModal = new Modal($('#newsletter-consent-modal'), { keyboard: false });

    let formToSubmit = null;

    $("#newsletter-form, #newsletter-form-sidebar").on("submit", function(e) {
        e.preventDefault();
        formToSubmit = $(this);
        newsletterConsentModal.show();
    });

    $("#newsletter-subscribe").on('click', function () {
        if (formToSubmit) {
            formToSubmit[0].submit();
            formToSubmit = null;
        }
    });
};
