import $ from "jquery";


export const filterBlogByCategory = () => {
    $("#category").on('change', function() {
        var category_id = $(this).val();
        if(category_id){
            $.ajax ({
                type: 'POST',
                url: "",
                data: {
                    csrfmiddlewaretoken: '{{ csrf_token }}',
                    category: category_id, 
                },
                success: function (data) { 
                    
                    $("#subcategory").html(data);
                },
                
            });
            
        }
    });
};

