import "../styles/main.scss";
import "bootstrap";
import $ from "jquery";

import { ProductRegistration } from "../components/productRegistration.js";
import { userProfileTabs } from "../components/userProfileTabs.js";
import { filterBlogByCategory } from "../components/blog_ajax.js";
import { footer } from "../components/footer.js";
import { header } from "../components/header.js";
import { brands } from "../components/brands.js";
import { statsBlock } from "../components/statsBlock";
import { collapsedBlock } from "../components/collapsedBlock";

import { shareBtn } from "../components/share_btn";
import { registration } from "../components/registration";
import { shoppingCart } from "../components/shoppingCart";
import { profile } from "../components/profile";
import { matchHeight } from "../components/matchHeight";
import { homeProductsCarousel } from "../components/homeProductCarousel";
import { statsCarousel } from "../components/statsCarousel";
import { initGoogleAnalytics } from "../components/googleAnalytics";
import { priceFormat } from "../components/priceFormat";
import { dateFormat } from "../components/dateFormat";
import { textFieldInit } from "../components/textFieldInit";
import { slimSelectInit } from "../components/slimSelectInit";
import { datePicker } from "../components/datePicker";
import { updateStateBasedOnCountry } from "../components/updateStateBasedOnCountry";
import { hideBuyonline } from "../components/hideBuyOnline";
import { SavedMoney } from "../components/SavedMoney";
import { ajaxSearch } from "../components/ajaxSearch";
// import { CountrySelectorModal } from "../components/CountrySelectorModal.js";
import { htmlToPdf } from "../components/htmlToPDF";
import CookieConsent from "../components/CookieConsent";
import { NewsletterConsent } from "../components/NewsletterConsent";
import { ConsentCheckbox } from "../components/ConsentCheckbox";
import { locallyScript } from "../components/locallyScript";
import { bazaarvoiceScript } from "../components/bazaarvoiceScript";
import { magicZoomControls } from "../components/magicZoomControls";
import { NewsletterPopup } from "../components/NewsletterPopup";
import "../components/magiczoomplus.js";
import { SubMenu } from "../components/subMenu.js";
import { Accordion } from "../components/sidebarAccordion.js";
import { SearchInput } from "../components/headerSearchInput.js";
import { headerBrandsCarousel } from "../components/headerBrandsCarousel.js";
import { PdpTabs } from "../components/pdpTabs.js";
import { featuresCarousel } from "../components/featuresCarousel.js";
import { ProductCarousel } from "../components/pdpProductCarousel.js";

$(function () {
    //GetCountryStates();
    // CountrySelectorModal();
    ProductRegistration();
    userProfileTabs();
    filterBlogByCategory();
    // countryCookie();
    footer();
    header();
    brands();
    statsBlock();
    collapsedBlock();
    shareBtn();
    registration();
    shoppingCart();
    profile();
    homeProductsCarousel();
    statsCarousel();
    initGoogleAnalytics();
    SavedMoney();
    ajaxSearch();
    priceFormat();
    dateFormat();
    textFieldInit();
    updateStateBasedOnCountry();
    slimSelectInit();
    datePicker();
    hideBuyonline();
    htmlToPdf();
    locallyScript();
    bazaarvoiceScript();
    matchHeight();
    CookieConsent.ready();
    NewsletterConsent();
    ConsentCheckbox();
    magicZoomControls();
    NewsletterPopup();
    SubMenu();
    Accordion();
    SearchInput();
    headerBrandsCarousel();
    PdpTabs();
    featuresCarousel();
    ProductCarousel();
});
