import $ from "jquery";
/* eslint-disable */
export const magicZoomControls = () => {


  $(document).ready(function () {
    $(".swiper-button-next").on("click",()=>{
        MagicZoom.next('Zoom-1');
    });

    $(".swiper-button-prev").on("click",()=>{
        MagicZoom.prev('Zoom-1');
    });


  });
};
