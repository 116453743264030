import $ from "jquery";

export const ProductRegistration = () => {
    let searchParams = new URLSearchParams(window.location.search);
    const zepAjaxUrl = $('#zep_ajax_url').val();

    const loader = (status, button) => {
        button.prop('disabled', status);
        status ? $('#loading-spinner').removeClass('d-none') : $('#loading-spinner').addClass('d-none');
    };

    const getProduct = (zepNumber, element) => {
        loader(true, element);
        $.ajax({
            type: "GET",
            url: `${zepAjaxUrl}?zep_number=${zepNumber}`,
            success: (data) => {
                loader(false, element);
                $('#id_category').val(data.category__name);
                $('#id_product_id').val(data.id);
                $('#id_brand option')
                    .removeAttr('selected')
                    .filter(`[value=${data.brands__id}]`)
                    .attr('selected', true);
                if (data.model_name) {
                    $('#id_model_name').val(data.model_name);
                    $('#id_model').val(data.model_name).focus();
                } else {
                    $('#id_model_name').val(data.description);
                }
            },
            error: (error) => {
                loader(false, element);
                let errorMessage = $.parseJSON(error.responseText);
                $("#error-message").show();
                $("#error-message").text(errorMessage.message);
            }
        });
    };

    $("#get-product").on('click', () => {
        const zepNumber = $('#id_zep').val();
        $("#error-message").hide();
        getProduct(zepNumber, $("#get-product"));
    });

    if (searchParams.has('zep_number')) {
        const zepNumber = searchParams.get('zep_number');
        $('#id_zep').val(zepNumber);
        getProduct(zepNumber, $("#get-product"));
    }
};

