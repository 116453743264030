import $ from "jquery";

export const ProductCarousel = () => {

    const imgNextSrc = $(".btn-wrap .next-btn").attr("src");
    const imgPrevSrc = $(".btn-wrap .prev-btn").attr("src");
    const ariaLabelNextText = $(".btn-wrap .next-btn").attr("aria-label");
    const ariaLabelPrevText = $(".btn-wrap .prev-btn").attr("aria-label");
    const altNextText = $(".btn-wrap .next-btn").attr("alt");
    const altPrevText = $(".btn-wrap .prev-btn").attr("alt");


    $(".owl-five").owlCarousel({
        items: 5,
        margin: 24,
        autoWidth: true,
        loop: true,
        nav: true,
        center: true,
        navContainer: '.custom-nav',
        navText: [
            `<img class="prev-btn" alt="${altPrevText}" aria-label="${ariaLabelPrevText}" src="${imgPrevSrc}" />`,
            `<img class="next-btn" alt="${altNextText}" aria-label="${ariaLabelNextText}" src="${imgNextSrc}" />`,
        ],
        dots: false,
    });

    // variants carousel
    $(".owl-six").owlCarousel({
        items: 10,
        margin: 16,
        autoWidth: true,
        nav: false,
        dots: false,
    });

    $(document).ready(function () {
        const $overlappingCard = $('#product-details-card');
        const $carousel = $('#new-carousel');
        const marginBottom = 28;

        function adjustBottomSpacing() {
            const position = $overlappingCard.css('position');
            const currentHeight = $overlappingCard.outerHeight(true);

            if (position === 'absolute') {
                const carouselHeight = $carousel.outerHeight();
                $carousel.css('margin-bottom', (currentHeight - carouselHeight + marginBottom) + 'px');
            } else if (position === 'static') {
                $carousel.css('margin-bottom', 0);
            }
        }

        // Debounce function to limit the rate of function calls
        function debounce(func, wait) {
            let timeout;
            return function executedFunction(...args) {
                const later = function () {
                    clearTimeout(timeout);
                    func(...args);
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            };
        }

        const debouncedAdjust = debounce(adjustBottomSpacing, 100);

        $(window).on('resize', debouncedAdjust);
        const observer = new ResizeObserver(debouncedAdjust);
        observer.observe($overlappingCard[0]);

        debouncedAdjust();
    });

};
