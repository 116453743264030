import $ from "jquery";

export const SavedMoney = (product) => {
  $(document).ready(function () {
    const langCode = $("#language-code").val(); 
    if (product) {

      const promoPrice = product.find(".msrp-price");
      const originalprice = product.find(".original-price");
      const savedMoney =  product.find(".saved-money");

      if (originalprice[0]) {
        let calcSavedMoney =
          Number(promoPrice[0].innerText.replace(/[$]/g, "").replace(",", ".")) -
          Number(
            originalprice[0].innerText
              .replace(/[$]/g, "")
              .replace(",", ".")
          );
        savedMoney[0].innerText = calcSavedMoney;
      }

      if ( product.find(".format-price")) {
        const pricesArr = product.find(".format-price").toArray();
        
        if (langCode == "fr-ca" || langCode == "en-ca") {
          pricesArr.forEach((price) => {
            const formatedPrice = new Intl.NumberFormat(langCode, {
              style: "currency",
              currency: "CAD",
            }).format(
              parseFloat(price.innerText.replace(/\$/g, "").replace(/,/g, "."))
            );
            price.innerText = formatedPrice;
          });
        } else {
          pricesArr.forEach((price) => {
            const formatedPrice = new Intl.NumberFormat(langCode, {
              style: "currency",
              currency: "USD",
            }).format(
              parseFloat(price.innerText.replace(/\$/g, "").replace(/,/g, "."))
            );
            price.innerText = formatedPrice;
          });
        }
      }
      
    } else {
      //get msrp price
      const promoPriceArr = $(".msrp-price").toArray();
      const originalpriceArr = $(".original-price").toArray();
      const savedMoneyArr = $(".saved-money").toArray();

      promoPriceArr.forEach((promoPrice, i) => {
        if (originalpriceArr[i]) {
          let savedMoney =
            Number(promoPrice.innerText.replace(/[$]/g, "").replace(",", ".")) -
            Number(
              originalpriceArr[i].innerText
                .replace(/[$]/g, "")
                .replace(",", ".")
            );

          // calculate percentage saved
          let percentageSaved = Math.round((savedMoney/originalpriceArr[i].innerText.replace(/[$,]/g, "."))*100);
          savedMoneyArr[i].innerText = percentageSaved;
        } else {
          Number(promoPrice.innerText.replace(/[$,]/g, "."));
        }
      });
    }
  });
};
