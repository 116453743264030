import $ from "jquery";
import { SavedMoney } from "./SavedMoney";
import { priceFormat } from "./priceFormat";
import { matchHeight } from "./matchHeight";
import { dateFormat } from "./dateFormat";
import { hideBuyonline } from "./hideBuyOnline";
import { locallyScript } from "./locallyScript";
import { magicZoomControls } from "./magicZoomControls";
import { shareBtn } from "./share_btn";
import { bazaarvoiceScript } from "./bazaarvoiceScript";
import { shoppingCart } from "./shoppingCart";
import { ProductCarousel } from "./pdpProductCarousel";
import { featuresCarousel } from "./featuresCarousel";
import { PdpTabs } from "./pdpTabs";

export const ajaxSearch = () => {
  $(document).ready(function () {
    const langCode = $("#language-code").val();

    const swatchColors = $(".swatch-colors");

    if (swatchColors.length > 0) {
      // on click of swatch color
      $("body").on("click", ".swatch-color", function () {
        //closet product-list-card
        const productCard = $(this).closest(".product-list-card");
        const slug = $(this).data("slug");
        const sku = $(this).data("sku");
        $(this).closest(".swatch-colors").find(".swatch-color").removeClass("active");
        $(this).addClass("active");
        if (productCard.length) {
          getVariant(productCard, slug, sku);
        } else {
          getVariantOnPDP(slug, sku);
        }
      });
    }

    const productsFilters = $("#products-filter");
    const blogsFilters = $("#blog-filters");
    const currentURL = $("#current-page").val();

    let currentProductPage = 1;
    let currentBlogPage = 1;
    let query = "";
    let filters = "";

    if ($("#search-input").val()) {
      query = $("#search-input").val();
    }

    //after ajax call
    let selectedTabs = [];
    let selectedFilter = [];
    let filtersCount = 0;
    let selectedOrder = "";
    let selectedBlogOrder = "";
    let pageCount = Number($(".total-pages").text());
    let blogPageCount = Number($(".blog-total-pages").text());

    const filterList = [
      "availableforsale",
      "categories",
      "colors",
      "brands",
      "product_use",
      "pricescale",
      "lengthscale",
      "weightscale",
      "person_capacity",
      "on_sale",
    ];

    function init() {
      selectedTabs = [];
      selectedFilter = [];
      filtersCount = 0;
      selectedOrder = "";
      selectedBlogOrder = "";
      pageCount = Number($(".total-pages").text());
      blogPageCount = Number($(".blog-total-pages").text());

      // init the filters based on the url query parameters
      const urlParams = new URLSearchParams(window.location.search);
      const query = urlParams.get("query");
      const order = urlParams.get("order");
      let filtersString = urlParams.toString();
      const blogOrder = urlParams.get("blog-order");
      const product_page = urlParams.get("product_page");
      const blog_page = urlParams.get("blog_page");
      // remove all elements from filters that is not contained in filterList
      filters = filtersString.split("&");
      filters = filters.filter(filter => {
        return filterList.includes(filter.split("=")[0]);
      });

      filtersCount = filters.length;

      if (query) {
        $("#search-input").val(query);
      }
      if (order) {
        $(".search-select-order").val(order);
        selectedOrder = order;
      }
      if (blogOrder) {
        $(".search-select-blog-order").val(blogOrder);
        selectedBlogOrder = blogOrder;
      }
      if (product_page) {
        currentProductPage = product_page;
      }
      if (blog_page) {
        currentBlogPage = blog_page;
      }

      if (filters) {
        filters.forEach(filter => {
          const filterArray = filter.split("=");
          const filterName = filterArray[0];
          const filterValue = decodeURIComponent(filterArray[1].replace(/\+/g, " "));
          $(`input[name="${filterName}"][value="${filterValue}"]`).prop("checked", true);
          // increment filterCount if the filter is checked
          if (!selectedFilter.includes(`${filterName}__${filterValue}`)) {
            selectedFilter.push(`${filterName}__${filterValue}`);
          }

          // push filter to selectedTabs if it is not already there
          if (!selectedTabs.includes(filterName)) {
            selectedTabs.push(filterName);
            if (blogsFilters.length > 0) {
              $(`.filter-group`).addClass("show");
            }
            $(`.filter-group[id="${filterName}"]`).addClass("show");
          }
        });
      }

      if (filtersCount > 0) {
        $(".mobile-filter-button-toggler").addClass("active");
        if ($("#products-filter")) {
          $(".filter-counter").text(filtersCount);
        }
        if ($("#blog-filters")) {
          $("#blog-filters").find(".filter-counter").text(filtersCount);
        }
      }
    }

    init();

    function clickFilter(e) {
      if (!selectedFilter.includes(`${e.target.name}__${e.target.value}`)) {
        selectedFilter.push(`${e.target.name}__${e.target.value}`);
        filtersCount++;
        currentBlogPage = 1;
        currentProductPage = 1;
      } else {
        selectedFilter = selectedFilter.filter(filter => {
          return filter !== `${e.target.name}__${e.target.value}`;
        });
        filtersCount--;
        currentBlogPage = 1;
        currentProductPage = 1;
      }

      getProducts();
    }

    function buildUrl() {
      // `${currentURL}?query=${query}&${filters}&blog-order=${blogOrder}&order=${selectedOrder}${activities.length > 0 ? '&product_use=' + activities : ''}&page=${currentPage}`
      let filters = `${currentURL}?`;
      selectedFilter.forEach(filter => {
        filter = filter.replace(/^(.*?)__(.*)$/, "$1=$2");
        filters += `${filter}&`;
      });

      filters += `query=${query}&`;
      filters += `order=${selectedOrder}&blog-order=${selectedBlogOrder}&product_page=${currentProductPage}&blog_page=${currentBlogPage}`;
      return filters;
    }

    /** Toggle mobile filter **/
    $(".toggle-filters").on("click", function () {
      $(".search-filter").slideToggle("up");
    });

    //add event delegation for inputs and push selected values to selectedfilter Array
    $(".product-container").on("change", 'input[type="checkbox"]', productsFilters, clickFilter);

    //update selectedTab array
    $(".product-container").on("click", ".filter-group", productsFilters, function (e) {
      let id;
      if (e.target.closest(".filter-group").id) {
        id = e.target.closest(".filter-group").id;
      }

      e.target.closest(".filter-group").classList.toggle("show");
      if (!selectedTabs.includes(id)) {
        selectedTabs.push(id);
      } else {
        selectedTabs = $.grep(selectedTabs, function (value) {
          return value !== id;
        });
      }
    });

    //search products
    $(".search-container").on("click", "#search-products-button", function (e) {
      e.preventDefault();
      query = $(this).prev().val();
      selectedOrder = "";
      currentProductPage = 1;
      currentBlogPage = 1;
      getProducts();
    });

    //Sort products
    $(".search-container").on("change", ".search-select-order", function (e) {
      e.preventDefault();
      selectedOrder = $(this).val();
      currentProductPage = 1;
      currentBlogPage = 1;
      getProducts();
    });
    //Sort blogs
    $(".search-container-blog").on("change", ".search-select-blog-order", function (e) {
      e.preventDefault();
      selectedBlogOrder = $(this).val();
      currentProductPage = 1;
      currentBlogPage = 1;

      getProducts();
    });
    //Pagination

    $(".product-container").on("click", "#products-pagination .pagination-next", function (e) {
      e.preventDefault();
      currentProductPage++;
      $(".product-scroll-into")[0]?.scrollIntoView({
        behavior: "smooth",
      });
      getProducts();
    });
    $(".product-container").on("click", "#blogs-pagination .pagination-next", function (e) {
      e.preventDefault();
      currentBlogPage++;

      $(".search-container-blog")[0]?.scrollIntoView({
        behavior: "smooth",
      });

      $(".blog-scroll-into")[0]?.scrollIntoView({
        behavior: "smooth",
      });

      getProducts();
    });

    $(".product-container").on("click", "#products-pagination .pagination-prev", function (e) {
      e.preventDefault();
      currentProductPage--;

      $(".product-scroll-into")[0]?.scrollIntoView({
        behavior: "smooth",
      });
      getProducts();
    });

    $(".product-container").on("click", "#blogs-pagination .pagination-prev", function (e) {
      e.preventDefault();
      currentBlogPage--;

      $(".search-container-blog")[0]?.scrollIntoView({
        behavior: "smooth",
      });

      $(".blog-scroll-into")[0]?.scrollIntoView({
        behavior: "smooth",
      });
      getProducts();
    });

    function paginationArrows(
      currentProductPage,
      productPageCount,
      currentBlogPage,
      blogPageCount
    ) {
      if (currentProductPage === 1) {
        $("#products-pagination").find("#products-pagination .pagination-prev").css({
          "opacity": "0",
          "pointer-events": "none",
        });
      } else if (currentProductPage > 1) {
        $("#products-pagination").find("#products-pagination .pagination-prev").css({
          "opacity": "1",
          "pointer-events": "auto",
        });
      }
      if (currentProductPage == productPageCount) {
        $("#products-pagination").find("#products-pagination pagination-next").css({
          "opacity": "0",
          "pointer-events": "none",
        });
      } else if (currentProductPage < productPageCount) {
        $("#products-pagination").find("#products-pagination .pagination-next").css({
          "opacity": "1",
          "pointer-events": "auto",
        });
      }

      // blog pagination
      if (currentBlogPage === 1) {
        $("#blogs-pagination").find("#blogs-pagination .pagination-prev").css({
          "opacity": "0",
          "pointer-events": "none",
        });
      } else if (currentBlogPage > 1) {
        $("#blogs-pagination").find("#blogs-pagination .pagination-prev").css({
          "opacity": "1",
          "pointer-events": "auto",
        });
      }

      if (currentBlogPage == blogPageCount) {
        $("#blogs-pagination").find("#blogs-pagination .pagination-next").css({
          "opacity": "0",
          "pointer-events": "none",
        });
      } else if (currentBlogPage < blogPageCount) {
        $("#blogs-pagination").find("#blogs-pagination .pagination-next").css({
          "opacity": "1",
          "pointer-events": "auto",
        });
      }
    }

    //reset filters

    $(".product-container").on("click", "#reset-filters", function () {
      filtersCount = 0;
      filters = "";
      currentProductPage = 1;
      currentBlogPage = 1;
      selectedFilter = [];

      getProducts();
    });

    function getProducts() {
      let Skelekton =
        '<div class="card-container"><div class="card-skelton"> <div class="card-img skeleton"></div> <div class="card-title first skeleton"></div> <div class="card-title second skeleton"></div><div class="card-title third skeleton"></div></div> </div>';
      let repeatedSkelekton = "";
      for (var i = 0; i < 21; i++) {
        repeatedSkelekton += Skelekton;
      }
      $("#products-list").html(repeatedSkelekton);
      $("#blogs-list").html(repeatedSkelekton);
      const url = buildUrl();
      $.ajax({
        url: url,
        method: "GET",
        dataType: "html",
        success: function (response) {
          history.pushState({}, "", url);

          //products template
          let productsList = $(response).find("#products-list").html();
          let productFilters = $(response).find("#products-filter").html();
          let mobileFilters = $(response).find("#mobile-filters").html();
          let totalProducts = $(response).find("#total-products").html();
          let pagination = $(response).find("#products-pagination").html();
          let blogPagination = $(response).find("#blogs-pagination").html();
          pageCount = Number($(response).find(".total-pages").text());
          blogPageCount = Number($(response).find(".blog-total-pages").text());

          //blog template
          let blogsList = $(response).find("#blogs-list").html();
          let blogsListOrder = $(response).find("#form-filter-order2");
          let totalBlogs = $(response).find("#total-articles").html();
          let blogFilters = $(response).find("#blog-filters").html();

          if (blogsList !== undefined) {
            $("#blogs-list").html(blogsList);
          } else {
            if (langCode == "fr-ca") {
              $("#form-filter-order2").html("");
              $("#blogs-list").html("<p class='no-results'>Aucun blogue trouvé</p>");
            } else {
              $("#form-filter-order2").html("");
              $("#blogs-list").html("<p class='no-results'>No blogs found</p>");
            }
          }
          $("#form-filter-order2").html(blogsListOrder);
          $("#total-articles").html(totalBlogs);
          //update blogs filters
          $("#blog-filters").html(blogFilters);
          //update blogs filter count
          $("#blog-filters").find(".filter-counter").text(filtersCount);

          //update products list
          $("#products-list").html(productsList);
          $("#mobile-filters").html(mobileFilters);
          //update products filters
          $("#products-filter").html(productFilters);
          //update products pagination
          $("#products-pagination").html(pagination);
          $("#blogs-pagination").html(blogPagination);

          //update total products
          $("#total-products").text(totalProducts);

          init();

          //manage pagination arrows
          paginationArrows(currentProductPage, pageCount, currentBlogPage, blogPageCount);
          if (
            (productsList && productsList.includes("no-results")) ||
            (productsList && productsList.includes("Pas de résultats"))
          ) {
            $("#products-pagination").hide();
          } else {
            $("#products-pagination").show();
          }

          if (!blogsList) {
            $("#blogs-pagination").hide();
          } else {
            $("#blogs-pagination").show();
          }

          SavedMoney();
          priceFormat();
          dateFormat();
          shoppingCart();
          hideBuyonline();
          bazaarvoiceScript();
          $(".add-to-cart-loading").fadeOut();
          matchHeight();
        },
        error: function (error) {
          // Handle errors
          console.log(error);
        },
      });
    }

    function getVariant(product, slug, sku) {
      const url = `/${langCode}/${
        langCode == "fr-ca" ? "produits" : "products"
      }/${slug}/${sku}?plp=true`;
      $.ajax({
        url: url,
        method: "GET",
        dataType: "html",
        success: function (response) {
          //get variant data
          let sku = $(response).find(".product-card-body__sku").html();
          let price = $(response).find(".product-card-body__price").html();
          let exlusiveModel = $(response).find(".product-card-body__text").html();
          let shopify_id = $(response).find(".add-to-cart-btn").attr("id");
          let image = $(response).find(".card-img-top").attr("src");
          let isSlodOnline = $(response).find(".is-sold-online").html();
          let product_available_for_sale_us = $(response).find(".is-available-for-sale-us").html();
          let product_available_for_sale_ca = $(response).find(".is-available-for-sale-ca").html();
          let gpl_or_smu = $(response).find(".product_gpl_or_smu").html();
          let label_new = $(response).find(".label-new");

          //replace link
          product.find(".product-card-img-contianer").attr("href", url);
          product.find(".product-card-body__title").attr("href", url);

          // // add label new
          if (label_new.length) {
            product.append(label_new);
          } else {
            product.find(".label-new").remove();
          }
          //update product data
          product.find(".product-card-body__sku").html(sku);

          if (exlusiveModel && exlusiveModel.toLocaleLowerCase().includes("excl")) {
            product.find(".product-card-body__price").html(exlusiveModel.split(",")[0]);
          } else {
            product.find(".product-card-body__price").html(price);
            SavedMoney(product);
          }

          const btn = product.find(".card-btn");

          if (langCode == "en-us") {
            if (
              isSlodOnline == "True" &&
              gpl_or_smu == "GPL" &&
              product_available_for_sale_us == "True"
            ) {
              btn.html(`<div class="add-to-cart-btn"  data-product-id="${shopify_id}"></div> `);
              const btnToUpdate = btn.find(".add-to-cart-container");
              shoppingCart(btnToUpdate);
            } else {
              btn.html(
                ` <a href="${url}" class="view-product-btn">  ${
                  langCode == "fr-ca" ? "Voir produit" : "View product"
                } </a> `
              );
            }
          } else {
            if (
              isSlodOnline == "True" &&
              gpl_or_smu == "GPL" &&
              product_available_for_sale_ca == "True"
            ) {
              btn.html(`<div class="add-to-cart-btn"  data-product-id="${shopify_id}"></div> `);
              const btnToUpdate = btn.find(".add-to-cart-btn");
              shoppingCart(btnToUpdate);
            } else {
              btn.html(
                ` <a href="${url}" class="view-product-btn">  ${
                  langCode == "fr-ca" ? "Voir produit" : "View product"
                } </a> `
              );
            }
          }

          // replace image
          if (image) {
            product.find(".card-img-top").attr("src", image);
          }

          // update prodcut reviews

          if (sku) {
            $("#bv-rating").attr("data-bv-product-id", `${sku}`);
          }

          matchHeight();
        },
        error: function (error) {
          // handle error
          console.log(error);
        },
      });
    }

    function getVariantOnPDP(slug, sku) {
      const url = `/${langCode}/${langCode == "fr-ca" ? "produits" : "products"}/${slug}/${sku}`;
      $.ajax({
        url: url,
        method: "GET",
        dataType: "html",
        success: function (response) {
          history.pushState({}, "", url);
          console.log(response);
          const productDetailContainer = $(response).find("#new-carousel");

          // update product details
          if (productDetailContainer) {
            const prevVariantsCarousel = $("#new-carousel").find("#variants-carousel");
            const productDetails = $(response).find(".tab-content-wraper");
            prevVariantsCarousel.find(".owl-nav").remove();
            $("#new-carousel").html(productDetailContainer);
            $("#new-carousel").find("#variants-carousel").html(prevVariantsCarousel);
            // COLOR NAME
            const colorName = $(response).find("#color-name");
            if (colorName) {
              $("#color-name").text(colorName.text());
            }

            if(productDetails) {
              $(".tab-content-wraper").html(productDetails);
            }
            locallyScript();
            hideBuyonline();
            SavedMoney();
            priceFormat();
            matchHeight();
            ProductCarousel();
            shoppingCart(productDetailContainer.find(".add-to-cart-btn"));
            magicZoomControls();
            /* eslint-disable */
            MagicZoom.start("Zoom-1");
            /* eslint-enable */
            shareBtn();
            featuresCarousel();
            PdpTabs();
            $('.tabs__item:first').click();
          }
        },
        error: function (error) {
          // handle error
          console.log(error);
        },
      });
    }
  });
};
