import $ from "jquery";
import { registerAttributes } from "./googleAnalytics.js";

export const shoppingCart = btn => {
  /*<![CDATA[*/

  (function () {
    var scriptURL = "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }

    function loadScript() {
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = ShopifyBuyInit;
    }

    function getCookie(name) {
      const cookieString = document.cookie;
      const cookies = cookieString.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + "=")) {
          const value = cookie.substring(name.length + 1);
          return decodeURIComponent(value);
        }
      }
      return null;
    }

    function getLocale(lang) {
      switch (lang) {
        case "en-us":
          return "en-US";
        case "en-ca":
          return "en-CA";
        case "fr-ca":
          return "fr-CA";
      }
      return "en-US";
    }

    async function ShopifyBuyInit() {
      var client = null;
      const djangoLanguageCookie = getCookie("django-language");
      if (djangoLanguageCookie === "en-us") {
        client = window.ShopifyBuy.buildClient({
          domain: "confluenceoutdoor.myshopify.com",
          storefrontAccessToken: "424367868a3705a366e89f802f44ebdc",
          language: "en-US",
        });
      } else if (djangoLanguageCookie === "en-ca") {
        client = window.ShopifyBuy.buildClient({
          domain: "confluenceoutdoorcanada.myshopify.com",
          storefrontAccessToken: "dc8fd71427e2651e211cb636cf0c5ad2",
          language: "en-CA",
        });
      } else {
        client = window.ShopifyBuy.buildClient({
          domain: "confluenceoutdoorcanada.myshopify.com",
          storefrontAccessToken: "dc8fd71427e2651e211cb636cf0c5ad2",
          language: "fr-FR",
        });
      }
      if (document.getElementById("product-brand")) {
        var productBrand = ""; // JSON.parse(document.getElementById('product-brand').textContent);
      }
      if (document.getElementById("product-category")) {
        var productCategory = ""; // JSON.parse(document.getElementById('product-category').textContent);
      }

      var userConfluenceId = $("#user-authentication").attr("data-user-cf-id");

      const addToCart = djangoLanguageCookie == "fr-ca" ? "Ajouter au panier" : "Add to cart";
      const outOfStock = djangoLanguageCookie == "fr-ca" ? "Épuisé en ligne" : "Out of stock";
      const checkoutButton = djangoLanguageCookie == "fr-ca" ? "Passer au paiement" : "Checkout";
      const subTotal = djangoLanguageCookie == "fr-ca" ? "Sous-total" : "Subtotal";
      const cartName = djangoLanguageCookie == "fr-ca" ? "Panier" : "Cart";
      const currency =
        djangoLanguageCookie == "en-ca" || djangoLanguageCookie == "fr-ca" ? "CAD" : "USD";
      const ui = await window.ShopifyBuy.UI.init(client);
      const cartEmpty =
        djangoLanguageCookie == "fr-ca" ? "Votre panier est vide" : "Your cart is empty";

      var options = {
        "option": {},

        "cart": {
          events: {
            beforeInit: function (cart) {
              var actualOpen = cart.checkout.open;
              cart.checkout.open = function (url) {
                var newUrl = new URL(url);
                newUrl.searchParams.set("locale", getLocale(djangoLanguageCookie));
                actualOpen.call(this, newUrl.toString());
                window.dataLayer && window.dataLayer?.push({ "event": "view_cart" });
              };
            },
            afterInit: function (cart) {
              $(".add-to-cart-loading").fadeOut();
              /**
               * Optional chaining because we cannot wait for cart
               */
              const totalCartQuantity = cart?.model?.lineItems?.reduce(
                (acc, lineItem) => acc + lineItem.quantity,
                0
              );
              $(".cart-count-text").text(totalCartQuantity);
              if (cart.model && $("#user-authentication").attr("data-user-record-id")) {
                var inp = {
                  "target": { "value": $("#user-authentication").attr("data-user-record-id") },
                };
                cart.setNote(inp);
              }
            },
            updateItemQuantity: function (cart) {
              let initialQuantity = 0;
              let retryCount = 0;
              const maxRetries = 10;
              function getCurrentQuantity() {
                let totalQuantity = 0;
                if (cart.lineItemCache) {
                  totalQuantity = cart.lineItemCache.reduce(
                    (acc, lineItem) => acc + lineItem.quantity,
                    0
                  );
                }
                return totalQuantity;
              }
              initialQuantity = getCurrentQuantity();
              function retryFetchQuantity() {
                let currentQuantity = getCurrentQuantity();
                if (currentQuantity !== initialQuantity || retryCount >= maxRetries) {
                  $(".cart-count-text").text(currentQuantity);
                } else {
                  retryCount++;
                  setTimeout(retryFetchQuantity, 100);
                }
              }
              retryFetchQuantity();
              if (cart.model && $("#user-authentication").attr("data-user-record-id")) {
                var inp = {
                  "target": { "value": $("#user-authentication").attr("data-user-record-id") },
                };
                cart.setNote(inp);
              }
            },
            afterRender: function (cart) {
              if (cart.model && $("#user-authentication").attr("data-user-record-id")) {
                var inp = {
                  "target": { "value": $("#user-authentication").attr("data-user-record-id") },
                };
                cart.setNote(inp);
              }
            },
          },

          "styles": {
            "button": {
              "font-family": "Roboto, sans-serif",
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px",
              ":hover": {
                "background-color": "#2b2b2b",
              },
              "background-color": "#343344",
              ":focus": {
                "background-color": "#2b2b2b",
              },
              "border-radius": "0px",
            },
            "note": {
              "display": "none",
            },
          },
          "text": {
            "title": cartName,
            "total": subTotal,
            "empty": cartEmpty,
            "noteDescription": $("#user-authentication").attr("data-user-record-id"),
            "noteTextArea": $("#user-authentication").attr("data-user-record-id"),
            "button": checkoutButton,
          },
          "contents": {
            "note": "test",
            "noteTextArea": $("#user-authentication").attr("data-user-record-id"),
            //"noteDescription": $('#user-authentication').attr('data-user-record-id'),
          },
          "popup": false,
          "googleFonts": ["Roboto"],
        },
        "toggle": {
          DOMEvents: {
            /*eslint-disable */
            "click": function (evt, target) {
              ui.openCart();
              registerAttributes({
                "event_category": "Open cart",
                "event_label": "view_cart",
                "user-id": userConfluenceId,
              });
            },
          },
          "sticky": false,
          "styles": {
            "toggle": {
              "font-family": "Roboto, sans-serif",
              "background-color": "transparent",
              "color": "black",
              "filter": "invert(100%)",
              "display": "inline-flex",
              "flex-direction": "row-reverse",
              "padding": "10px 0 !important",
              ":hover": {
                "background-color": "transparent",
              },
              ":focus": {
                "background-color": "transparent",
                "border": 0,
              },
            },
            "count": {
              "filter": "invert(100%)",
              "font-size": "12px",
              "color": "white",
              "background-color": "black",
              "border-radius": "50%",
              "width": "20px",
              "height": "20px",
              "margin-top": "-10px",
              "margin-left": "0 !important",
              "display": "inline-flex !important",
              "justify-content": "center",
              "align-items": "center",
            },
          },
          "googleFonts": ["Roboto"],
        },
      };

      options["product"] = {
        events: {
          addVariantToCart: function (product) {
            var quantity = 0;
            const currentCartQuantityText = $(".cart-count-text").eq(0).text();
            const currentCartQuantity = parseInt(currentCartQuantityText, 10);
            const selectedQuantity = parseInt(product.selectedQuantity, 10);
            if (!isNaN(currentCartQuantity) && !isNaN(selectedQuantity)) {
              typeof currentCartQuantity === "number" && typeof selectedQuantity === "number";
              const total = currentCartQuantity + selectedQuantity;
              $(".cart-count-text").text(total);
            }

            if (product.cart.lineItemCache[0]) {
              quantity = product.cart.lineItemCache[0].quantity += product.selectedQuantity;
            } else {
              quantity = product.selectedQuantity;
            }
            const addToCart = {
              "event_label": "add_to_cart",
              "user_id": userConfluenceId,
              "product_id": product.model.variants[0].sku,
              "product_name": product.model.title,
              "product_category": $(".product-sku").attr("data-product-category"),
              "product_currency": currency,
              "product_brand": $(".product-sku").attr("data-brand"),
              "product_price": product.model.variants[0].price,
              "product_quantity": quantity,
              "product_discount": 0, // product.cart.model.discountApplications
            };

            window.dataLayer && window.dataLayer?.push({ "event": "add_to_cart", ...addToCart });
            // registerAttributes(addToCart);
          },
          afterInit: function (product) {
            var quantity = 0;
            if (product.cart.lineItemCache[0]) {
              quantity = product.cart.lineItemCache[0].quantity += product.selectedQuantity;
            } else {
              quantity = product.selectedQuantity;
            }
            const viewItem = {
              "event_category": "View product details page",
              "event_label": "view_item",
              "user-id": userConfluenceId,
              "item_id": product.model.variants[0].sku,
              "item_name": product.model.title,
              "item_category": productCategory,
              "currency": currency,
              "item_brand": productBrand,
              "price": product.model.variants[0].price,
              "quantity": quantity,
              "discount": null, //product.cart.model.discounts,
            };
            registerAttributes(viewItem);
          },
        },
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "calc(25% - 20px)",
              "margin-left": "45px",
              "margin-bottom": "50px",
            },
            "text-align": "left",
          },
          "button": {
            "font-family": "Roboto, sans-serif",
            "font-size": "16px",
            "padding-top": "16px",
            "padding-bottom": "16px",
            ":hover": {
              "background-color": "#2b2b2b",
            },
            "background-color": "#343344",
            "background-image": "url('{% static 'img/icons/add_shopping_cart.svg' %}')",
            "background-repeat": " no-repeat",
            "background-position-y": "center",
            "background-position-x": "25%",
            "padding-left": "" /* adjust as needed */,
            ":focus": {
              "background-color": "#343344",
            },
            "border-radius": "50px",
            "width": "200px",
            "display": "flex",
            "align-items": "center",
            "justify-content": "center",
            "margin": "0 !important",
            "@media (max-width: 920px)": {
              "width": "100% !important",
            },
          },
          "quantityInput": {
            "font-size": "16px",
            "padding-top": "16px",
            "padding-bottom": "16px",
          },
        },
        "contents": {
          "img": false,
          "title": false,
          "price": false,
        },
        "text": {
          "button": addToCart,
          "outOfStock": outOfStock,
        },
        "googleFonts": ["Roboto"],
      };
      options["productSet"] = {
        "styles": {
          "products": {
            "@media (min-width: 601px)": {
              "margin-left": "-20px",
            },
          },
        },
      };
      options["modalProduct"] = {
        "contents": {
          "img": false,
          "imgWithCarousel": true,
          "button": false,
          "buttonWithQuantity": true,
        },
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "100%",
              "margin-left": "0px",
              "margin-bottom": "0px",
            },
          },
          "button": {
            "font-family": "Roboto, sans-serif",
            "font-size": "16px",
            "padding-top": "16px",
            "padding-bottom": "16px",
            ":hover": {
              "background-color": "#2b2b2b",
            },
            "background-color": "#191919",
            ":focus": {
              "background-color": "#2b2b2b",
            },
            "border-radius": "0px",
            "padding-left": "97px",
            "padding-right": "97px",
          },
          "quantityInput": {
            "font-size": "16px",
            "padding-top": "16px",
            "padding-bottom": "16px",
          },
        },
        "googleFonts": ["Roboto"],
        "text": {
          "button": addToCart,
        },
      };

      const initShoppingCart = () => {
        if (btn) {
          ui.createComponent("product", {
            id: btn.attr("data-product-id"),
            node: btn[0],
            moneyFormat: "%24%7B%7Bamount%7D%7D",
            toggles: [{ node: document.getElementById("login-nav-cart") }],
            options: options,
          });
        } else if ($(".add-to-cart-btn").length > 0) {
          $(".add-to-cart-btn")
            .toArray()
            .forEach(element => {
              ui.createComponent("product", {
                id: $(element).attr("data-product-id"),
                node: element,
                moneyFormat: "%24%7B%7Bamount%7D%7D",
                toggles: [{ node: document.getElementById("login-nav-cart") }],
                options: options,
              });
            });
        } else {
          ui.createComponent("product", {
            moneyFormat: "%24%7B%7Bamount%7D%7D",
            toggles: [{ node: document.getElementById("login-nav-cart") }],
            options: options,
          });
        }
      };

      $(document).ready(function () {
        // Init shopping cart
        initShoppingCart();
        $(".shopify-cart-btn").on("click", function () {
          event.stopPropagation();
          ui.openCart();
        });
      });
    }
  })();
  /*]]>*/
};
