import $ from "jquery";

export const priceFormat = () => {
  $(document).ready(function () {
    const langCode = $("#language-code").val(); 

    if ($(".format-price")) {
      const pricesArr = $(".format-price").toArray();
      
      if (langCode == "fr-ca" || langCode == "en-ca") {
        pricesArr.forEach((price) => {
          const formatedPrice = new Intl.NumberFormat(langCode, {
            style: "currency",
            currency: "CAD",
          }).format(
            parseFloat(price.innerText.replace(/\$/g, "").replace(/,/g, "."))
          );
          price.innerText = formatedPrice;
        });
      } else {
        pricesArr.forEach((price) => {
          const formatedPrice = new Intl.NumberFormat(langCode, {
            style: "currency",
            currency: "USD",
          }).format(
            parseFloat(price.innerText.replace(/\$/g, "").replace(/,/g, "."))
          );
          price.innerText = formatedPrice;
        });
      }
    }
  });
};
